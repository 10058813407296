import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {useCodeViewOptions} from '@github-ui/use-code-view-options'
import {Box} from '@primer/react'
import React, {useEffect, useImperativeHandle, useRef} from 'react'

import {useCurrentLineHeight} from '../../../../hooks/use-current-line-height'
import {useIsCursorEnabled} from '../../../../hooks/use-cursor-navigation'
import {useManualRender} from '../../../../hooks/use-manual-render'
import {isLineInViewport, useIsFirefox, useShouldUseInert} from '../../../../utilities/lines'
import SelectAllShortcutButton from '../../../../utilities/SelectAllShortcutButton'
import type {CodeLinesHandle} from './code-lines-handle'
import {CodeFoldingEllipsisOverlay} from './CodeFoldingEllipsisOverlay'
import {type CodeLinesProps, getHorizontalScrollOffset, handleBlobScrollSync} from './CodeLines'
import {HighlightedLinesOverlay} from './HighlightedLinesOverlay'
import {HighlightedSymbolsOverlay} from './HighlightedSymbolsOverlay'
import {LineNumberNoVirtualziation} from './LineNumberNoVirtualization'
import {StickyLineObserverOverlay} from './StickyLineObserverOverlay'
import {SyntaxHighlightedOverlay} from './SyntaxHighlightedLine'

export const CodeLinesNoVirtualization = React.memo(React.forwardRef(CodeLinesNoVirtualizationUnmemoized))
export const numberOfLinesPerContentVisibility = 60
export const noVirtualizationThreshold = 3500

function CodeLinesNoVirtualizationUnmemoized(
  {
    linesData,
    onLineNumberClick,
    codeSections,
    nonTruncatedLinesData,
    codeLineToSectionMap,
    onLineStickOrUnstick,
    colorizedLines,
    tabSize,
    contentWidth,
    cursorContainerRef,
    onCollapseToggle,
    textAreaRef,
    copilotAccessAllowed,
  }: CodeLinesProps,
  ref: React.ForwardedRef<CodeLinesHandle>,
) {
  const parentRef = useRef<HTMLTableElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const horizontalScrollBarRef = useRef<HTMLDivElement>(null)
  const shouldUseCursor = useIsCursorEnabled()
  const reRender = useManualRender()
  // Prevent loops and jumping when scrolling
  const setNextTextAreaScroll = useRef(true)
  const setNextScrollBarScroll = useRef(true)
  const setNextScrollContainerScroll = useRef(true)
  const lineHeight = useCurrentLineHeight('react-line-numbers')

  //we want to wrap every 60 lines in a data-visibility wrapper, so we need
  //to make this array to map over down below
  const lineArrayMap = useRef([...Array(Math.floor(linesData.length / numberOfLinesPerContentVisibility) + 1).keys()])

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (isLineInViewport(0)) return
      window.scrollTo({left: 0, top: 0})
    },
    scrollToLine: (lineNumber, column) => {
      const container = parentRef.current
      if (!container) {
        return
      }

      setTimeout(
        () =>
          window.scrollTo({
            left: getHorizontalScrollOffset(container, lineNumber, column),
            top: lineHeight * lineNumber,
          }),
        0,
      )
    },
  }))

  useEffect(() => {
    //need to set the on scroll here because it relies on having access to parentRef to sync up the scrolls
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.onscroll = () => {
        if (scrollContainerRef.current && textAreaRef?.current) {
          if (!setNextScrollContainerScroll.current) {
            setNextScrollContainerScroll.current = true
            return
          }

          if (textAreaRef.current.scrollLeft === scrollContainerRef.current.scrollLeft) return

          // Goes in a loop textArea -> contianer -> scrollBar -> textArea , so we need to prevent the next scroll on the textArea
          setNextTextAreaScroll.current = !(setNextScrollBarScroll.current && setNextScrollContainerScroll.current)
          scrollContainerRef.current.scrollLeft = textAreaRef.current.scrollLeft
        }
        if (cursorContainerRef && cursorContainerRef.current && textAreaRef?.current) {
          cursorContainerRef.current.scrollLeft = textAreaRef.current.scrollLeft
        }
      }
      const textRef = textAreaRef.current
      return () => {
        if (textRef) {
          textRef.onscroll = null
        }
      }
    }
  }, [textAreaRef, parentRef, shouldUseCursor, cursorContainerRef])

  useEffect(() => {
    lineArrayMap.current = [...Array(Math.floor(linesData.length / numberOfLinesPerContentVisibility) + 1).keys()]
    reRender()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesData])

  const scrollingProps = shouldUseCursor
    ? {overflowX: 'overlay', scrollbarWidth: 'none', '&::-webkit-scrollbar': {display: 'none'}}
    : {overflowX: 'auto'}

  return (
    <Box
      ref={parentRef}
      sx={{pointerEvents: shouldUseCursor ? 'none' : 'auto'}}
      onScroll={event => handleBlobScrollSync(event, textAreaRef)}
    >
      <Box
        ref={scrollContainerRef}
        sx={scrollingProps}
        //TODO: this is necessary to resolve an axe complaint, but we don't actually want this to be in the tab order.
        //unsure what the resolution to that issue is outside of an onFocus
        tabIndex={0}
        onScroll={() => {
          if (shouldUseCursor && scrollContainerRef.current && horizontalScrollBarRef.current) {
            if (!setNextScrollBarScroll.current) {
              setNextScrollBarScroll.current = true
              return
            }

            if (horizontalScrollBarRef.current.scrollLeft === scrollContainerRef.current.scrollLeft) return
            // Goes in a loop contianer -> scrollBar -> textArea -> container, so we need to prevent the next scroll on the container
            setNextScrollContainerScroll.current = !(setNextScrollBarScroll.current && setNextTextAreaScroll.current)
            horizontalScrollBarRef.current.scrollLeft = scrollContainerRef.current.scrollLeft
          }
        }}
      >
        <Box
          className="react-code-file-contents"
          role="presentation"
          aria-hidden={true}
          data-tab-size={tabSize}
          data-paste-markdown-skip
          sx={{
            tabSize,
            position: 'relative',
            width: contentWidth,
            maxWidth: 'unset',
          }}
          data-hpc
        >
          <div
            className="react-line-numbers-no-virtualization"
            style={{
              pointerEvents: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            {lineArrayMap.current.map(segmentCount => {
              const tempLines = linesData.slice(
                segmentCount * numberOfLinesPerContentVisibility,
                Math.min(
                  segmentCount * numberOfLinesPerContentVisibility + numberOfLinesPerContentVisibility,
                  linesData.length,
                ),
              )
              return (
                <div
                  key={`line-number-wrapper-${segmentCount}-content:${tempLines[0]?.rawText?.substring(0, 100)}`}
                  className="react-no-virtualization-wrapper-lines"
                >
                  {tempLines.map(lineData => {
                    return (
                      <LineNumberNoVirtualziation
                        codeLineData={lineData}
                        key={`line-number-${lineData.lineNumber}-content:${lineData.rawText?.substring(0, 100)}`}
                        onClick={onLineNumberClick}
                        ownedCodeSections={codeSections}
                        onLineStickOrUnstick={onLineStickOrUnstick}
                        onCollapseToggle={onCollapseToggle}
                        codeLineToSectionMap={codeLineToSectionMap}
                        copilotAccessAllowed={copilotAccessAllowed}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div className="react-code-lines react-code-lines-no-virtualization">
            <HighlightedSymbolsOverlay linesData={linesData} />
            <HighlightedLinesOverlay linesData={linesData} copilotAccessAllowed={copilotAccessAllowed} />
            <SyntaxHighlightedOverlay colorizedLines={colorizedLines} linesData={nonTruncatedLinesData!} />
            <StickyLineObserverOverlay
              linesData={linesData}
              onLineStickOrUnstick={onLineStickOrUnstick}
              codeLineToSectionMap={codeLineToSectionMap}
            />
            <CodeFoldingEllipsisOverlay
              linesData={linesData}
              onLineStickOrUnstick={onLineStickOrUnstick}
              setIsCollapsed={onCollapseToggle}
              tabSize={tabSize}
              contentWidth={contentWidth}
            />
          </div>
          <SelectAllShortcutButton
            shouldNotOverrideCopy={shouldUseCursor}
            containerRef={shouldUseCursor ? textAreaRef : parentRef}
          />
        </Box>
      </Box>
      {shouldUseCursor &&
        contentWidth &&
        scrollContainerRef.current &&
        scrollContainerRef.current.clientWidth < contentWidth && (
          <Box
            sx={{
              width: '100%',
              pointerEvents: 'auto',
              overflowX: 'auto',
              overflowY: 'hidden',
              height: '17px',
              position: 'sticky',
              bottom: 0,
              zIndex: 2,
            }}
            onScroll={() => {
              if (horizontalScrollBarRef.current && textAreaRef?.current) {
                if (!setNextTextAreaScroll.current) {
                  setNextTextAreaScroll.current = true
                  return
                }

                if (horizontalScrollBarRef.current.scrollLeft === textAreaRef.current.scrollLeft) return

                // Goes in a loop scrollBar -> textArea -> container -> scrollBar, so we need to prevent the next scroll on the scrollBar
                setNextScrollBarScroll.current = !(
                  setNextScrollContainerScroll.current && setNextTextAreaScroll.current
                )
                textAreaRef.current.scrollLeft = horizontalScrollBarRef.current.scrollLeft
              }
            }}
            ref={horizontalScrollBarRef}
            // Prevent cursor events
            onClick={ev => ev.preventDefault()}
            onMouseDown={ev => ev.preventDefault()}
            onMouseUp={ev => ev.preventDefault()}
          >
            <Box sx={{width: contentWidth, height: '1px'}} />
          </Box>
        )}
    </Box>
  )
}

export function useShouldDoNoVirtualization(fileLength: number) {
  const wordWrapEnabled = useCodeViewOptions().codeWrappingOption.enabled
  const isNonVirtualized = useFeatureFlag('react_blob_overlay')
  const shouldUseInert = useShouldUseInert()
  const isFirefox = useIsFirefox()
  const isOldFirefox = isFirefox && !shouldUseInert

  //we want to virtualize beyond 3500 lines because that is when performance starts to significantly degrade
  return isNonVirtualized && !wordWrapEnabled && !isOldFirefox && fileLength < noVirtualizationThreshold
}

try{ CodeLinesNoVirtualization.displayName ||= 'CodeLinesNoVirtualization' } catch {}
try{ CodeLinesNoVirtualizationUnmemoized.displayName ||= 'CodeLinesNoVirtualizationUnmemoized' } catch {}